import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, vModelText as _vModelText, withDirectives as _withDirectives, Transition as _Transition, toDisplayString as _toDisplayString, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "buttons" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "content" }
const _hoisted_6 = { class: "hint" }
const _hoisted_7 = { class: "ok-button" }
const _hoisted_8 = { class: "token-form" }
const _hoisted_9 = { id: "tokenForm" }
const _hoisted_10 = {
  key: 0,
  class: "code-inputs"
}
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { class: "bullet-list" }
const _hoisted_13 = {
  key: 0,
  class: "ticket-button"
}
const _hoisted_14 = { key: 2 }
const _hoisted_15 = { class: "content" }
const _hoisted_16 = { class: "hint" }
const _hoisted_17 = { class: "ok-button" }
const _hoisted_18 = {
  key: 0,
  class: "wait"
}
const _hoisted_19 = { class: "info-text" }
const _hoisted_20 = { class: "wait-text" }
const _hoisted_21 = { key: 3 }
const _hoisted_22 = { class: "content" }
const _hoisted_23 = {
  key: 1,
  class: "buttons"
}
const _hoisted_24 = { class: "button-bar" }
const _hoisted_25 = { key: 4 }
const _hoisted_26 = { class: "content" }
const _hoisted_27 = {
  key: 0,
  class: "wait"
}
const _hoisted_28 = { key: 5 }
const _hoisted_29 = { class: "content" }
const _hoisted_30 = {
  key: 0,
  class: "wait"
}
const _hoisted_31 = { class: "info-text" }
const _hoisted_32 = { class: "wait-text" }
const _hoisted_33 = { key: 0 }

export function render(_ctx, _cache) {
  const _component_DemoHeader = _resolveComponent("DemoHeader")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_dialog = _resolveComponent("el-dialog")
  const _component_el_col = _resolveComponent("el-col")
  const _component_el_row = _resolveComponent("el-row")
  const _component_CircleCheckFilled = _resolveComponent("CircleCheckFilled")
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_Ticket = _resolveComponent("Ticket")
  const _component_TestHeader = _resolveComponent("TestHeader")
  const _component_Clock = _resolveComponent("Clock")
  const _component_QuestionSelect = _resolveComponent("QuestionSelect")
  const _component_QuestionPanel = _resolveComponent("QuestionPanel")
  const _component_ArrowLeft = _resolveComponent("ArrowLeft")
  const _component_ArrowRight = _resolveComponent("ArrowRight")
  const _component_Help = _resolveComponent("Help")
  const _component_Header = _resolveComponent("Header")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.phase == 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_DemoHeader, {
            windowWidth: _ctx.windowWidth,
            windowHeight: _ctx.windowHeight,
            showHelp: false,
            onOnHelp: _ctx.onHelpClick
          }, null, 8, ["windowWidth", "windowHeight", "onOnHelp"]),
          _createElementVNode("div", _hoisted_2, [
            _cache[52] || (_cache[52] = _createElementVNode("div", { class: "welcome" }, [
              _createTextVNode(" Herzlich willkommen bei der Demoversion von "),
              _createElementVNode("label", { class: "brand" }, "MC.health"),
              _createTextVNode(". ")
            ], -1)),
            _cache[53] || (_cache[53] = _createElementVNode("p", { class: "main-info" }, "In den schriftlichen Prüfungen werden Fragen in unterschiedlicher Form gestellt.", -1)),
            _cache[54] || (_cache[54] = _createElementVNode("p", { class: "main-info" }, "Bitte wählen Sie, falls bekannt, die für Sie zutreffende Variante:", -1)),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", {
                class: "button-big",
                onClick: _cache[0] || (_cache[0] = $event => (_ctx.onStartClick(0)))
              }, " Single Choice: Auswahl einer korrekten Antwort aus fünf Optionen. "),
              _createElementVNode("div", {
                class: "button-big",
                onClick: _cache[1] || (_cache[1] = $event => (_ctx.onStartClick(1)))
              }, " Multiple Choice: Auswahl einer oder mehrerer korrekter Antworten aus fünf Optionen. ")
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.phase == 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_DemoHeader, {
            windowWidth: _ctx.windowWidth,
            windowHeight: _ctx.windowHeight,
            onOnHelp: _ctx.onHelpClick
          }, null, 8, ["windowWidth", "windowHeight", "onOnHelp"]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_el_dialog, {
              modelValue: _ctx.showHint1,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.showHint1) = $event)),
              title: "Hinweise zur Benutzung",
              top: "10vw",
              width: (_ctx.windowWidth > 982 ? '60vw' : '90vw'),
              "append-to-body": "",
              center: ""
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_6, [
                  _cache[56] || (_cache[56] = _createElementVNode("h1", null, "So läuft die online Prüfung", -1)),
                  _cache[57] || (_cache[57] = _createElementVNode("p", { class: "step" }, "1. Schritt", -1)),
                  _cache[58] || (_cache[58] = _createElementVNode("p", { class: "info" }, "Jeder Prüfling bekommt ein Ticket vom zuständigen Gesundheitsamt zugesandt.", -1)),
                  _cache[59] || (_cache[59] = _createElementVNode("p", { class: "info" }, "Für diese Demoversion lautet das Ticket:", -1)),
                  _cache[60] || (_cache[60] = _createElementVNode("p", { class: "step" }, "MC-HEALTH", -1)),
                  _cache[61] || (_cache[61] = _createElementVNode("p", { class: "info" }, "Sobald Sie dieses Ticket korrekt in das Eingabefeld eingegeben haben, erscheint ein Button für die Anmeldung.", -1)),
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_el_button, {
                      type: "primary",
                      class: "nav",
                      onClick: _ctx.onContinue
                    }, {
                      default: _withCtx(() => _cache[55] || (_cache[55] = [
                        _createTextVNode("Verstanden")
                      ])),
                      _: 1
                    }, 8, ["onClick"])
                  ])
                ])
              ]),
              _: 1
            }, 8, ["modelValue", "width"]),
            _createElementVNode("div", _hoisted_8, [
              _cache[69] || (_cache[69] = _createElementVNode("div", { class: "welcome" }, " Willkommen zum schriftlichen Teil der Kenntnisüberprüfungen zum Erwerb der Heilpraktikererlaubnis. ", -1)),
              _cache[70] || (_cache[70] = _createElementVNode("div", { class: "token-head" }, "Geben Sie bitte Ihre Teilnehmer-ID ein:", -1)),
              _createElementVNode("form", _hoisted_9, [
                (_ctx.windowWidth >= 982)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createVNode(_component_el_row, { class: "token-row" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_col, {
                            class: "token",
                            span: 1.5
                          }, {
                            default: _withCtx(() => [
                              _withDirectives(_createElementVNode("input", {
                                id: "token-0",
                                "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.tokenArray[0]) = $event)),
                                class: "token",
                                type: "text",
                                maxlength: "1",
                                onInput: _cache[4] || (_cache[4] = $event => (_ctx.onTokenEnter($event, 0))),
                                autofocus: ""
                              }, null, 544), [
                                [_vModelText, _ctx.tokenArray[0]]
                              ])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_el_col, {
                            class: "token",
                            span: 1.5
                          }, {
                            default: _withCtx(() => [
                              _withDirectives(_createElementVNode("input", {
                                id: "token-1",
                                "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => ((_ctx.tokenArray[1]) = $event)),
                                class: "token",
                                type: "text",
                                maxlength: "1",
                                onInput: _cache[6] || (_cache[6] = $event => (_ctx.onTokenEnter($event, 1))),
                                onKeydown: _cache[7] || (_cache[7] = $event => (_ctx.onKeyDown($event, 1)))
                              }, null, 544), [
                                [_vModelText, _ctx.tokenArray[1]]
                              ])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_el_col, {
                            class: "divider",
                            span: 1.5
                          }, {
                            default: _withCtx(() => _cache[62] || (_cache[62] = [
                              _createTextVNode(" − ")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_el_col, {
                            class: "token",
                            span: 1.5
                          }, {
                            default: _withCtx(() => [
                              _withDirectives(_createElementVNode("input", {
                                id: "token-2",
                                "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => ((_ctx.tokenArray[2]) = $event)),
                                class: "token",
                                type: "text",
                                maxlength: "1",
                                onInput: _cache[9] || (_cache[9] = $event => (_ctx.onTokenEnter($event, 2))),
                                onKeydown: _cache[10] || (_cache[10] = $event => (_ctx.onKeyDown($event, 2)))
                              }, null, 544), [
                                [_vModelText, _ctx.tokenArray[2]]
                              ])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_el_col, {
                            class: "token",
                            span: 1.5
                          }, {
                            default: _withCtx(() => [
                              _withDirectives(_createElementVNode("input", {
                                id: "token-3",
                                "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => ((_ctx.tokenArray[3]) = $event)),
                                class: "token",
                                type: "text",
                                maxlength: "1",
                                onInput: _cache[12] || (_cache[12] = $event => (_ctx.onTokenEnter($event, 3))),
                                onKeydown: _cache[13] || (_cache[13] = $event => (_ctx.onKeyDown($event, 3)))
                              }, null, 544), [
                                [_vModelText, _ctx.tokenArray[3]]
                              ])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_el_col, {
                            class: "token",
                            span: 1.5
                          }, {
                            default: _withCtx(() => [
                              _withDirectives(_createElementVNode("input", {
                                id: "token-4",
                                "onUpdate:modelValue": _cache[14] || (_cache[14] = $event => ((_ctx.tokenArray[4]) = $event)),
                                class: "token",
                                type: "text",
                                maxlength: "1",
                                onInput: _cache[15] || (_cache[15] = $event => (_ctx.onTokenEnter($event, 4))),
                                onKeydown: _cache[16] || (_cache[16] = $event => (_ctx.onKeyDown($event, 4)))
                              }, null, 544), [
                                [_vModelText, _ctx.tokenArray[4]]
                              ])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_el_col, {
                            class: "token",
                            span: 1.5
                          }, {
                            default: _withCtx(() => [
                              _withDirectives(_createElementVNode("input", {
                                id: "token-5",
                                "onUpdate:modelValue": _cache[17] || (_cache[17] = $event => ((_ctx.tokenArray[5]) = $event)),
                                class: "token",
                                type: "text",
                                maxlength: "1",
                                onInput: _cache[18] || (_cache[18] = $event => (_ctx.onTokenEnter($event, 5))),
                                onKeydown: _cache[19] || (_cache[19] = $event => (_ctx.onKeyDown($event, 5)))
                              }, null, 544), [
                                [_vModelText, _ctx.tokenArray[5]]
                              ])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_el_col, {
                            class: "token",
                            span: 1.5
                          }, {
                            default: _withCtx(() => [
                              _withDirectives(_createElementVNode("input", {
                                id: "token-6",
                                "onUpdate:modelValue": _cache[20] || (_cache[20] = $event => ((_ctx.tokenArray[6]) = $event)),
                                class: "token",
                                type: "text",
                                maxlength: "1",
                                onInput: _cache[21] || (_cache[21] = $event => (_ctx.onTokenEnter($event, 6))),
                                onKeydown: _cache[22] || (_cache[22] = $event => (_ctx.onKeyDown($event, 6)))
                              }, null, 544), [
                                [_vModelText, _ctx.tokenArray[6]]
                              ])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_el_col, {
                            class: "token",
                            span: 1.5
                          }, {
                            default: _withCtx(() => [
                              _withDirectives(_createElementVNode("input", {
                                id: "token-7",
                                "onUpdate:modelValue": _cache[23] || (_cache[23] = $event => ((_ctx.tokenArray[7]) = $event)),
                                class: "token",
                                type: "text",
                                maxlength: "1",
                                onInput: _cache[24] || (_cache[24] = $event => (_ctx.onTokenEnter($event, 7))),
                                onKeydown: _cache[25] || (_cache[25] = $event => (_ctx.onKeyDown($event, 7)))
                              }, null, 544), [
                                [_vModelText, _ctx.tokenArray[7]]
                              ])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      _withDirectives(_createElementVNode("input", {
                        id: "token-0",
                        "onUpdate:modelValue": _cache[26] || (_cache[26] = $event => ((_ctx.tokenArray[0]) = $event)),
                        class: "token-mobile",
                        type: "text",
                        maxlength: "1",
                        onInput: _cache[27] || (_cache[27] = $event => (_ctx.onTokenEnter($event, 0))),
                        autofocus: ""
                      }, null, 544), [
                        [_vModelText, _ctx.tokenArray[0]]
                      ]),
                      _withDirectives(_createElementVNode("input", {
                        id: "token-1",
                        "onUpdate:modelValue": _cache[28] || (_cache[28] = $event => ((_ctx.tokenArray[1]) = $event)),
                        class: "token-mobile",
                        type: "text",
                        maxlength: "1",
                        onInput: _cache[29] || (_cache[29] = $event => (_ctx.onTokenEnter($event, 1))),
                        onKeydown: _cache[30] || (_cache[30] = $event => (_ctx.onKeyDown($event, 1)))
                      }, null, 544), [
                        [_vModelText, _ctx.tokenArray[1]]
                      ]),
                      _cache[63] || (_cache[63] = _createElementVNode("div", { class: "divider-mobile" }, null, -1)),
                      _withDirectives(_createElementVNode("input", {
                        id: "token-2",
                        "onUpdate:modelValue": _cache[31] || (_cache[31] = $event => ((_ctx.tokenArray[2]) = $event)),
                        class: "token-mobile",
                        type: "text",
                        maxlength: "1",
                        onInput: _cache[32] || (_cache[32] = $event => (_ctx.onTokenEnter($event, 2))),
                        onKeydown: _cache[33] || (_cache[33] = $event => (_ctx.onKeyDown($event, 2)))
                      }, null, 544), [
                        [_vModelText, _ctx.tokenArray[2]]
                      ]),
                      _withDirectives(_createElementVNode("input", {
                        id: "token-3",
                        "onUpdate:modelValue": _cache[34] || (_cache[34] = $event => ((_ctx.tokenArray[3]) = $event)),
                        class: "token-mobile",
                        type: "text",
                        maxlength: "1",
                        onInput: _cache[35] || (_cache[35] = $event => (_ctx.onTokenEnter($event, 3))),
                        onKeydown: _cache[36] || (_cache[36] = $event => (_ctx.onKeyDown($event, 3)))
                      }, null, 544), [
                        [_vModelText, _ctx.tokenArray[3]]
                      ]),
                      _withDirectives(_createElementVNode("input", {
                        id: "token-4",
                        "onUpdate:modelValue": _cache[37] || (_cache[37] = $event => ((_ctx.tokenArray[4]) = $event)),
                        class: "token-mobile",
                        type: "text",
                        maxlength: "1",
                        onInput: _cache[38] || (_cache[38] = $event => (_ctx.onTokenEnter($event, 4))),
                        onKeydown: _cache[39] || (_cache[39] = $event => (_ctx.onKeyDown($event, 4)))
                      }, null, 544), [
                        [_vModelText, _ctx.tokenArray[4]]
                      ]),
                      _withDirectives(_createElementVNode("input", {
                        id: "token-5",
                        "onUpdate:modelValue": _cache[40] || (_cache[40] = $event => ((_ctx.tokenArray[5]) = $event)),
                        class: "token-mobile",
                        type: "text",
                        maxlength: "1",
                        onInput: _cache[41] || (_cache[41] = $event => (_ctx.onTokenEnter($event, 5))),
                        onKeydown: _cache[42] || (_cache[42] = $event => (_ctx.onKeyDown($event, 5)))
                      }, null, 544), [
                        [_vModelText, _ctx.tokenArray[5]]
                      ]),
                      _withDirectives(_createElementVNode("input", {
                        id: "token-6",
                        "onUpdate:modelValue": _cache[43] || (_cache[43] = $event => ((_ctx.tokenArray[6]) = $event)),
                        class: "token-mobile",
                        type: "text",
                        maxlength: "1",
                        onInput: _cache[44] || (_cache[44] = $event => (_ctx.onTokenEnter($event, 6))),
                        onKeydown: _cache[45] || (_cache[45] = $event => (_ctx.onKeyDown($event, 6)))
                      }, null, 544), [
                        [_vModelText, _ctx.tokenArray[6]]
                      ]),
                      _withDirectives(_createElementVNode("input", {
                        id: "token-7",
                        "onUpdate:modelValue": _cache[46] || (_cache[46] = $event => ((_ctx.tokenArray[7]) = $event)),
                        class: "token-mobile",
                        type: "text",
                        maxlength: "1",
                        onInput: _cache[47] || (_cache[47] = $event => (_ctx.onTokenEnter($event, 7))),
                        onKeydown: _cache[48] || (_cache[48] = $event => (_ctx.onKeyDown($event, 7)))
                      }, null, 544), [
                        [_vModelText, _ctx.tokenArray[7]]
                      ])
                    ]))
              ]),
              _cache[71] || (_cache[71] = _createElementVNode("div", { class: "bullet-head" }, "Mit der Anmeldung bestätigen Sie, dass", -1)),
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_el_row, { class: "bullet-item" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_col, {
                      span: 0.5,
                      class: "bullet-icon"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_icon, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_CircleCheckFilled)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_col, {
                      span: 20,
                      class: "bullet-text"
                    }, {
                      default: _withCtx(() => _cache[64] || (_cache[64] = [
                        _createTextVNode(" Sie sich körperlich, geistig und psychisch in der Lage fühlen, die Prüfung anzutreten. ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_row, { class: "bullet-item" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_col, {
                      span: 0.5,
                      class: "bullet-icon"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_icon, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_CircleCheckFilled)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_col, {
                      span: 20,
                      class: "bullet-text"
                    }, {
                      default: _withCtx(() => _cache[65] || (_cache[65] = [
                        _createTextVNode(" Sie keine Hilfsmittel zur Beantwortung der Fragen nutzen werden. ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_row, { class: "bullet-item" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_col, {
                      span: 0.5,
                      class: "bullet-icon"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_icon, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_CircleCheckFilled)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_col, {
                      span: 20,
                      class: "bullet-text"
                    }, {
                      default: _withCtx(() => _cache[66] || (_cache[66] = [
                        _createTextVNode(" Ihnen bekannt ist, dass ein Betrugsversuch bei der Prüfung zum Nichtbestehen führt. ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_row, { class: "bullet-item" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_col, {
                      span: 0.5,
                      class: "bullet-icon"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_icon, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_CircleCheckFilled)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_col, {
                      span: 20,
                      class: "bullet-text"
                    }, {
                      default: _withCtx(() => _cache[67] || (_cache[67] = [
                        _createTextVNode(" Sie die Kosten für die Prüfung nach der Gebührenordnung der zuständigen Behörde tragen werden. ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _createVNode(_Transition, { name: "el-fade-in-linear" }, {
                default: _withCtx(() => [
                  (_ctx.showButton)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                        _createVNode(_component_el_button, {
                          class: "ticket",
                          onClick: _ctx.onSubmit
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_icon, { size: "max(4vw, 50px)" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_Ticket)
                              ]),
                              _: 1
                            }),
                            _cache[68] || (_cache[68] = _createTextVNode("  Anmelden "))
                          ]),
                          _: 1
                        }, 8, ["onClick"])
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.phase == 2)
      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
          _createVNode(_component_TestHeader, {
            windowWidth: _ctx.windowWidth,
            windowHeight: _ctx.windowHeight,
            token: _ctx.ticket,
            targetgroup: _ctx.targetGroup,
            testdate: _ctx.testDate,
            time: _ctx.minutesLeft,
            questions_answered: _ctx.questions_answered,
            questions_total: _ctx.questions_total,
            showHelp: true,
            onOnHelp: _ctx.onHelpClick
          }, null, 8, ["windowWidth", "windowHeight", "token", "targetgroup", "testdate", "time", "questions_answered", "questions_total", "onOnHelp"]),
          _createElementVNode("div", _hoisted_15, [
            _createVNode(_component_el_dialog, {
              modelValue: _ctx.showHint2,
              "onUpdate:modelValue": _cache[49] || (_cache[49] = $event => ((_ctx.showHint2) = $event)),
              title: "Hinweise zur Benutzung",
              width: (_ctx.windowWidth > 982 ? '60vw' : '90vw'),
              "append-to-body": "",
              center: ""
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_16, [
                  _cache[73] || (_cache[73] = _createElementVNode("p", { class: "step" }, "2. Schritt", -1)),
                  _cache[74] || (_cache[74] = _createElementVNode("p", { class: "info" }, "Jeder Prüfling muss nach der Anmeldung auf den Start der Prüfung warten, bis alle Prüflinge bereit sind.", -1)),
                  _cache[75] || (_cache[75] = _createElementVNode("p", { class: "info" }, "In der Demoversion kann dieser Prozess nur simuliert werden.", -1)),
                  _cache[76] || (_cache[76] = _createElementVNode("p", { class: "info" }, "Bitte warten Sie nun ca. 5 Sekunden. Danach beginnt die Prüfung für alle Teilnehmenden gleichzeitig.", -1)),
                  _createElementVNode("div", _hoisted_17, [
                    _createVNode(_component_el_button, {
                      type: "primary",
                      class: "nav",
                      onClick: _ctx.onContinue
                    }, {
                      default: _withCtx(() => _cache[72] || (_cache[72] = [
                        _createTextVNode("Ok, ich warte.")
                      ])),
                      _: 1
                    }, 8, ["onClick"])
                  ])
                ])
              ]),
              _: 1
            }, 8, ["modelValue", "width"]),
            (!_ctx.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                  _createVNode(_component_el_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_col, {
                        span: 0.1,
                        class: "info-logo"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_icon, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_Clock)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_col, {
                        span: 10,
                        class: "info-wait"
                      }, {
                        default: _withCtx(() => _cache[77] || (_cache[77] = [
                          _createElementVNode("p", null, [
                            _createTextVNode("Bitte haben Sie"),
                            _createElementVNode("br"),
                            _createTextVNode("noch etwas Geduld.")
                          ], -1)
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _cache[81] || (_cache[81] = _createElementVNode("div", { class: "info-header" }, " Herzlich willkommen. ", -1)),
                  _createElementVNode("div", _hoisted_19, [
                    _createElementVNode("div", _hoisted_20, [
                      _cache[78] || (_cache[78] = _createElementVNode("p", null, "Die Prüfung beginnt erst, wenn alle anwesenden Prüflinge angemeldet sind.", -1)),
                      _cache[79] || (_cache[79] = _createElementVNode("p", null, "Bei Freigabe der Prüfung durch das Aufsichtspersonal, beginnt sie für alle teilnehmenden Personen gleichzeitig.", -1)),
                      _createElementVNode("p", null, "Die Prüfungsdauer beträgt " + _toDisplayString(_ctx.minutesLeft) + " Minuten für " + _toDisplayString(_ctx.questions_total) + " Fragen.", 1)
                    ]),
                    _cache[80] || (_cache[80] = _createElementVNode("h3", null, "Viel Erfolg!", -1))
                  ])
                ]))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.phase == 3 && !_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
          _createVNode(_component_TestHeader, {
            windowWidth: _ctx.windowWidth,
            windowHeight: _ctx.windowHeight,
            token: _ctx.ticket,
            targetgroup: _ctx.targetGroup,
            testdate: _ctx.testDate,
            time: _ctx.minutesLeft,
            questions_answered: _ctx.questions_answered,
            questions_total: _ctx.questions_total,
            showHelp: true,
            isRunning: true,
            showFinish: true,
            onOnHelp: _ctx.onHelpClick
          }, null, 8, ["windowWidth", "windowHeight", "token", "targetgroup", "testdate", "time", "questions_answered", "questions_total", "onOnHelp"]),
          _createElementVNode("div", _hoisted_22, [
            (_ctx.windowWidth >= 982)
              ? (_openBlock(), _createBlock(_component_QuestionSelect, {
                  key: 0,
                  windowWidth: _ctx.windowWidth,
                  windowHeight: _ctx.windowHeight,
                  questionid: _ctx.questionid,
                  list: _ctx.questionList,
                  onOnSelect: _ctx.onQuestionSelect
                }, null, 8, ["windowWidth", "windowHeight", "questionid", "list", "onOnSelect"]))
              : _createCommentVNode("", true),
            _createVNode(_component_QuestionPanel, {
              windowWidth: _ctx.windowWidth,
              windowHeight: _ctx.windowHeight,
              complexity: _ctx.complexity,
              questionModel: _ctx.questionList[_ctx.questionid-1],
              optionList: _ctx.optionList[_ctx.complexity][_ctx.questionid-1],
              onOnAnswer: _ctx.onAnswerClick
            }, null, 8, ["windowWidth", "windowHeight", "complexity", "questionModel", "optionList", "onOnAnswer"]),
            (!_ctx.loading && _ctx.questionid > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                  _createElementVNode("div", _hoisted_24, [
                    _createVNode(_component_el_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_col, {
                          span: 8,
                          align: "left"
                        }, {
                          default: _withCtx(() => [
                            (_ctx.windowWidth >= 982)
                              ? (_openBlock(), _createBlock(_component_el_button, {
                                  key: 0,
                                  disabled: _ctx.questionid <= 1,
                                  type: "primary",
                                  class: "nav",
                                  onClick: _ctx.onPreviousQuestionClick
                                }, {
                                  default: _withCtx(() => _cache[82] || (_cache[82] = [
                                    _createTextVNode("Vorherige Frage")
                                  ])),
                                  _: 1
                                }, 8, ["disabled", "onClick"]))
                              : (_openBlock(), _createBlock(_component_el_button, {
                                  key: 1,
                                  disabled: _ctx.questionid <= 1,
                                  type: "primary",
                                  class: "nav",
                                  onClick: _ctx.onPreviousQuestionClick,
                                  circle: ""
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_icon, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ArrowLeft)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }, 8, ["disabled", "onClick"]))
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_el_col, {
                          span: 8,
                          align: "center"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_button, {
                              type: "primary",
                              class: "nav",
                              onClick: _ctx.onFinishTestClick
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.questions_answered < _ctx.questions_total ? 'Prüfung beenden' : 'Prüfung abschließen'), 1)
                              ]),
                              _: 1
                            }, 8, ["onClick"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_el_col, {
                          span: 8,
                          align: "right"
                        }, {
                          default: _withCtx(() => [
                            (_ctx.windowWidth >= 982)
                              ? (_openBlock(), _createBlock(_component_el_button, {
                                  key: 0,
                                  disabled: _ctx.questionid >= 5,
                                  type: "primary",
                                  class: "nav",
                                  onClick: _ctx.onNextQuestionClick
                                }, {
                                  default: _withCtx(() => _cache[83] || (_cache[83] = [
                                    _createTextVNode("Nächste Frage")
                                  ])),
                                  _: 1
                                }, 8, ["disabled", "onClick"]))
                              : (_openBlock(), _createBlock(_component_el_button, {
                                  key: 1,
                                  disabled: _ctx.questionid >= 5,
                                  type: "primary",
                                  class: "nav",
                                  onClick: _ctx.onNextQuestionClick,
                                  circle: ""
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_icon, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ArrowRight)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }, 8, ["disabled", "onClick"]))
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  (!_ctx.loading && _ctx.windowWidth < 892)
                    ? (_openBlock(), _createBlock(_component_QuestionSelect, {
                        key: 0,
                        windowWidth: _ctx.windowWidth,
                        windowHeight: _ctx.windowHeight,
                        list: _ctx.demoModel[1],
                        questionid: _ctx.questionid,
                        onOnSelect: _ctx.onQuestionSelect
                      }, null, 8, ["windowWidth", "windowHeight", "list", "questionid", "onOnSelect"]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_el_dialog, {
              modelValue: _ctx.showHint3,
              "onUpdate:modelValue": _cache[50] || (_cache[50] = $event => ((_ctx.showHint3) = $event)),
              title: "Hilfe"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Help, {
                  helpIndex: 1,
                  questionCount: _ctx.questions_total,
                  testMode: _ctx.complexity,
                  timeLimit: _ctx.minutesLeft,
                  windowWidth: _ctx.windowWidth,
                  windowHeight: _ctx.windowHeight
                }, null, 8, ["questionCount", "testMode", "timeLimit", "windowWidth", "windowHeight"])
              ]),
              _: 1
            }, 8, ["modelValue"])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.phase == 6)
      ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
          _createVNode(_component_Header, {
            windowWidth: _ctx.windowWidth,
            windowHeight: _ctx.windowHeight
          }, null, 8, ["windowWidth", "windowHeight"]),
          _createElementVNode("div", _hoisted_26, [
            (!_ctx.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                  _cache[84] || (_cache[84] = _createElementVNode("div", { class: "info-header" }, " Vielen Dank für Ihre Teilnahme. ", -1)),
                  _cache[85] || (_cache[85] = _createElementVNode("div", { class: "info-text" }, [
                    _createElementVNode("div", { class: "wait-text" }, [
                      _createElementVNode("p", null, "An dieser Stelle ist die Demoversion beendet."),
                      _createElementVNode("p", null, "In einer echten Prüfung würden Ihre Antworten vom System ausgewertet und an den Prüfer gesendet werden."),
                      _createElementVNode("p", null, "Sie erhalten zu einem späteren Zeitpunkt Bescheid über das Prüfungsergebnis von der zuständigen Behörde.")
                    ]),
                    _createElementVNode("p", null, "Auf Wiedersehen.")
                  ], -1)),
                  _createElementVNode("div", {
                    class: "button-big",
                    onClick: _cache[51] || (_cache[51] = (...args) => (_ctx.onGoHomeClick && _ctx.onGoHomeClick(...args)))
                  }, " Zurück zur Startseite ")
                ]))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.phase == 9)
      ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
          _createVNode(_component_TestHeader, {
            windowWidth: _ctx.windowWidth,
            windowHeight: _ctx.windowHeight,
            token: _ctx.ticket,
            targetgroup: _ctx.targetGroup,
            testdate: _ctx.testDate,
            time: _ctx.minutesLeft,
            questions_answered: _ctx.questions_answered,
            questions_total: _ctx.questions_total,
            isRunning: true,
            showHelp: true,
            onOnHelp: _ctx.onHelpClick
          }, null, 8, ["windowWidth", "windowHeight", "token", "targetgroup", "testdate", "time", "questions_answered", "questions_total", "onOnHelp"]),
          _createElementVNode("div", _hoisted_29, [
            (!_ctx.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                  _cache[89] || (_cache[89] = _createElementVNode("div", { class: "info-header" }, " Prüfung beenden? ", -1)),
                  _createElementVNode("div", _hoisted_31, [
                    _createElementVNode("div", _hoisted_32, [
                      ((_ctx.questions_total - _ctx.questions_answered) > 0)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_33, "Sie haben " + _toDisplayString(_ctx.questions_total - _ctx.questions_answered) + " von " + _toDisplayString(_ctx.questions_total) + " Fragen nicht beantwortet.", 1))
                        : _createCommentVNode("", true),
                      _createElementVNode("p", null, "Bis zum Prüfungsende haben Sie noch " + _toDisplayString(_ctx.minutesLeft) + " Minuten Zeit.", 1),
                      _cache[86] || (_cache[86] = _createElementVNode("p", null, "Sind Sie sicher, dass Sie die Prüfung jetzt beenden möchten?", -1))
                    ]),
                    _createVNode(_component_el_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_col, {
                          span: 0.1,
                          class: "button",
                          align: "left"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_button, {
                              type: "primary",
                              class: "nav",
                              onClick: _ctx.onCancelFinishClick
                            }, {
                              default: _withCtx(() => _cache[87] || (_cache[87] = [
                                _createTextVNode("Nein, zurück zum Fragebogen")
                              ])),
                              _: 1
                            }, 8, ["onClick"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_el_col, {
                          span: 7,
                          class: "button",
                          align: "left"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_button, {
                              type: "danger",
                              class: "cancel",
                              onClick: _ctx.onConfirmFinishClick
                            }, {
                              default: _withCtx(() => _cache[88] || (_cache[88] = [
                                _createTextVNode("Ja, Prüfung jetzt beenden")
                              ])),
                              _: 1
                            }, 8, ["onClick"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ])
                ]))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}